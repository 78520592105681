require('../scss/index.scss');
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import '@fortawesome/fontawesome-free/js/all.js';

// import Swiper JS
import Swiper, {Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/css';
import 'swiper/css/navigation';

// import isotope
import Isotope from 'isotope-layout';

// import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
// import imagesLoaded from 'imagesloaded';
import Masonry from 'masonry-layout';

import 'bootstrap';

// jQueryBridget( 'imagesLoaded', imagesLoaded, $ );
jQueryBridget('masonry', Masonry, $);

jQuery.noConflict();

var imagesLoaded = require('imagesloaded');


// ============================================================================
// WINDOW LOAD

$(window).on('load', function() {

    // prevent default anchor scroll
    preventDefaultAnchorScroll()

    AOS.init();

    // prepare parallax effect
    // loadParallax();
    // updateParallax();

    // hidden p
    $('p').each(function(){
        if($(this).css('display') == 'none'){
            $(this).addClass('hidden-p');
        } 
    });

    // unstyle richtext
    if ($('.unstyle')[0]) unstyleRichText('.unstyle')

    // mapa about
    if ($('.template-sobre')[0]) prepareMap();

    // load masonry
    setTimeout(function () {
        loadIsotope();
        $('.masonry-grid').css('opacity',1);
    }, 500);
    
    // make body visible
    $('body').removeClass('hidden');

    // // smooth scroll to hash (anchor) if there is one
    // checkLocationHash();

    // more buttons
    $('.more').on('click', ()=>{
        $('.description').toggleClass('open');
        $('.more').toggleClass('active');
        $(".hidden-p").slideToggle("slow", function(){});
    });

    // rise on load
    $('.sol').addClass('rising');

    // floating nav obra
    // if ($('.template-single-obra')[0]) prepareFloatingNav();

    // load header
    if (window.scrollY < 100) {
        header.css('background-color', 'rgba(0, 0, 0, 0)');
    } 

    $("input:radio[name=acompanante]").click(function() {
        if($('input:radio[name=acompanante]:checked').val() == "1"){
            $('.acompanante_email').slideDown();
            $('#acompanante_email').attr('required', 'true');
        } else {
            $('.acompanante_email').slideUp();
            $('#acompanante_email').removeAttr('required');
        }
    });
    
    $('.button').on('click', function(){
        $('.pop-up').addClass('active');
    });

    $('.button').on('click', function(){
        $('.pop-up').addClass('active');
    });

    $('.pop-up .close').on('click', function(){
        $('.pop-up').removeClass('active');
    });

    $('.success-message .close').on('click', function(){
        $('.success-message').fadeOut();
    });

});



// ============================================================================
// WINDOW SCROLL

let previousScroll = 0;
let ticking = false;
let header = $("header");

document.addEventListener('scroll', function(e) {

    if (!ticking) {

        window.requestAnimationFrame(function() {
            var scroll = window.scrollY;

            if (scroll < 100) {
                header.css('background-color', 'rgba(0, 0, 0, 0)');
                header.css('top', '0');
            } 
            else if (scroll > previousScroll) { 
                header.css('top', '-200px');
            } 
            else if (scroll < vh(80)) { 
                header.css('background-color', 'rgba(0, 0, 0, 0)');
                header.css('top', '0');
            } else {
                if (header.css('background-color') == 'rgba(0, 0, 0, 0)') {
                    header.removeAttr('style');
                    header.css('top', '0');
                } else {
                    header.css('top', '0');
                }
            }

            previousScroll = scroll;
            ticking = false;
        });
    
        ticking = true;
    }

    // updateParallax(); 

});


// .................................................................................
// PARALLAX EFFECT
// .................................................................................

// let parallaxArray = [];
// let parallax_items = $('.deco-parallax');

// function loadParallax(){

//     parallax_items.each(function(i){
//         let item = $(this);
    
//         if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {

//             parallaxArray[i] = parseInt(item.css('top'));
//         } else {
//             parallaxArray[i] = parseInt(item.css('bottom'));
//         }

//     });
// }

// function updateParallax(){

//     parallax_items.each(function(i){
//         let item = $(this);

//         let speed = parseFloat($(this).data('speed'));
//         if (!speed) speed = -0.1;

//         if ( item.hasClass('top') || item.hasClass('mid') || item.hasClass('relative')) {
//             setPropretyParallax(item, speed, 'top', parallaxArray[i]);
//         } 
//     });
// }


// function setPropretyParallax(element, ratio, value, orig) {
    
//     var pos = orig - ratio * ($(window).scrollTop() - element.offset().top);
//     pos = pos.toFixed(1);
//     element.css(value, pos/10 + 'rem');

// }  


// .................................................................................
// POSTCARD SLIDE
// .................................................................................

// $('.js-postcard-slider').on('mouseenter', function() {
//     let id = $(this).data('id');
//     let postcard = $(`#${id}`);
//     if (postcard[0]) postcard.addClass('selected');  
// });

// $('.js-postcard-slider').on('mouseleave', function() {
//     let id = $(this).data('id');
//     let postcard = $(`#${id}`);
//     if (postcard[0]) postcard.removeClass('selected');
// });




var swiper_obra = new Swiper('#obra-swiper', {
    // slidesPerView: 3,
    spaceBetween: 20,
    // autoHeight: true,
    calculateHeight:true,
    navigation: {
        nextEl: ".arrow-right",
        prevEl: ".arrow-left",
    },
});

// Floating Nav

// function prepareFloatingNav() {

//     const target = $("#floating-nav");
//     const trigger = $("#floating-nav-trigger-top");
//     // const trigger_top = $("#floating-nav-trigger-top");
//     // const trigger_bottom = $("#floating-nav-trigger-bottom");

//     setTimeout(updateFloatingNav, 2000);
//     $(window).on('scroll', updateFloatingNav);

//     function updateFloatingNav() {

//         let boom = true;

//         let scroll = $(window).scrollTop();
//         let win_h = $(window).height();
//         // let top = trigger_top.offset().top;
//         // let bottom = trigger_bottom.offset().top;
//         let top = trigger.offset().top;
//         let bottom = trigger.offset().top + trigger.outerHeight();

//         console.log(scroll + win_h, top, top - win_h*0.1,'-', scroll + win_h, bottom + win_h*0.2);

//         if ((scroll + win_h < top + 0.62*win_h ) || (scroll > bottom + 0.8*win_h )) {
//             boom = false;
//         }

//         if (boom) {
//             target.removeClass('hide');
//         } else {
//             target.addClass('hide');
//         }
//     }
    
// }

// Menu hamburger

$("#hamburger").on('click',()=> {$("#main-menu").toggleClass("expanded");});


// mapa about

function prepareMap() {
    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
 
    mapboxgl.accessToken = 'pk.eyJ1IjoiZWRlYWxmb25zbyIsImEiOiJjbDJzd2RuczQwMnVnM2NvM2Q0am5mbTE4In0.7Ukhguf4Ncqi-12ChAeKHQ';
    
    var map = new mapboxgl.Map({
        container: 'map-box',
        style: 'mapbox://styles/edealfonso/cl2u4s9j2009r14mfdd7totk3',
        // padding: 400,
        // center: [-3.678715, 40.432295],
        // zoom: 16
    });

    map.scrollZoom.disable();

    map.on('load', function () {
        map.resize();
        $('#map-box').css('opacity', '1');

        // disable map zoom when using scroll
    });
}


// unstyle richtext

function unstyleRichText(wrapper_selector) {

    let restyleWrapper = document.querySelectorAll(wrapper_selector);

    for (let i = 0; i < restyleWrapper.length; ++i) {
        let elements = restyleWrapper[i].querySelectorAll('p, span');
        for (let j = 0; j < elements.length; ++j) {
            elements[j].removeAttribute("style");
        }
    }

}

// form newsletter 

let newsletterForm = $('#newsletter-form');

newsletterForm.on('submit', function(event){

    event.preventDefault();
    let msg_slot = $('#mailchimp-success');
    let lang = $('html').attr('lang');

    $.ajax({
        type: 'POST',
        url: $('#newsletter-form').data('url'),
        data: {
            'email': $('#newsletter-email').val(),
            'csrfmiddlewaretoken': $('input[name=csrfmiddlewaretoken]').val(),
        },

        success: function (res) {
            if (lang === 'en') {
                msg_slot.html('Thank you for subscribing!');
            } else {
                msg_slot.html('¡Gracias por subscribirte!');
            }
            newsletterForm.hide();
            msg_slot.removeClass('error');
            msg_slot.fadeIn();

        },

        error: function (res) {
            if (lang === 'en') {
                msg_slot.html('An error has occurred. Please, review the e-mail address and try again.');
            } else {
                msg_slot.html('Ha habido un error. Por favor, revisa la dirección proporcionada y prueba de nuevo.');
            }
            msg_slot.addClass('error');
            msg_slot.fadeIn();
        }
    });
});


// ISOTOPE MASONRY
// ============================================================================

let loadIsotope = function () {

    var list = document.querySelectorAll('.masonry-grid');

    for (let element of list) {

        // var iso = new Isotope( element , {
        //     itemSelector: '.masonry-item',
        //     // layoutMode: 'fitRows',
        //     // layoutMode: 'masonry',
        //     masonry: {
        //         horizontalOrder: true,
        //     },
        // });      
        
        // setTimeout(function () {
        //     iso.layout();
        // }, 2000);

    }

    imagesLoaded( '#masonry-grid', function() {

        // images have loaded

        $('#masonry-grid').masonry({
            // options
            itemSelector: '.masonry-item',
            // columnWidth: 300
        });
    });
}

// ============================================================================
// auxiliary
// ============================================================================

// https://stackoverflow.com/questions/44109314/javascript-calculate-with-viewport-width-height
function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

// ----------------------------------------
// location hash smooth scroll
// ----------------------------------------

const the_hash = location.hash;

function checkLocationHash() {
    console.log('hash', the_hash);
    if (the_hash) {
        smoothScrollTo($(the_hash));
    }
}

function smoothScrollTo(element) {
    $('body, html').animate({
        scrollTop: element.offset().top
    },1000);
}

function preventDefaultAnchorScroll() {
    $(window).scrollTop(0);
}

